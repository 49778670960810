<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.fullName || userData.username }}
        </p>
        <span class="user-status">{{ userData.role }}</span>
      </div>
      <b-avatar
        size="40"
        v-if="imageUrl==''"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <!-- <feather-icon
          v-if="!userData.fullName"
          icon="UserIcon"
          size="22"
        /> -->
      </b-avatar>
      <b-avatar
        v-else
        size="40"
        :src="imageUrl"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <!-- <feather-icon
          v-if="!userData.fullName"
          icon="UserIcon"
          size="22"
        /> -->
      </b-avatar>
    </template>

    <!-- <b-dropdown-item
      :to="{ name: 'pages-profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CheckSquareIcon"
        class="mr-50"
      />
      <span>Task</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-chat' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>Chat</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>Pricing</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>FAQ</span>
    </b-dropdown-item> -->
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import firebase from "@/utils/firebaseInit";
import firebaseGlobal from 'firebase/app';
import 'firebase/firestore';
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js'
import { dbCollections } from '@/utils/firebaseCollection'
const messaging = firebase.messaging()
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import firebaseTimeStamp from "firebase";
export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      imageUrl:''
    }
  },
  created() {
    var self = this
    if(self.userData.role !== 'admin' && this.$route.name !== 'student-information' && this.$route.name !== 'studentapplicationEditDetails' && this.$route.name !== 'edit-employee-profile') {
      getQuery.getDataFromRootCollectionWithDocID(dbCollections.USERS,self.userData.id,res=>{ 
          self.userData.fullName = res.name;
          self.userData.username = res.name;
          localStorage.setItem('userData',JSON.stringify({...self.userData,'fullName': res.name,'username': res.name}))
          localStorage.setItem('currentUser',JSON.stringify({...self.userData,'fullName': res.name,'username': res.name}))
      })
    }
    self.$root.$on('changeProfile',(imageUrl)=>{
      self.imageUrl = imageUrl
    })   
    self.$root.$on('changeLoggedUserName',(username)=>{
      self.userData.fullName = username;
      self.userData.username = username;
    })
    self.$root.$on('logoutUser',()=>{
      self.logout()
    })
  },
  methods: {
    // logout() {
    //   // Remove userData from localStorage
    //   // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
    //   localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
    //   localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
    //   // Remove userData from localStorage
    //   localStorage.removeItem('userData')
    //   localStorage.removeItem('userAccess');
    //   // Reset ability
    //   this.$ability.update(initialAbility)
    //   //FIREBASE COLLECTION USER DETAIL
    //   localStorage.removeItem('currentUser')     
    //    firebase
    //     .auth()
    //     .signOut()
    //     .then(() => {
    //       this.$router.push({
    //         name: 'auth-login' 
    //       });
    //     });
    
    // },
    logout() {
      let token = localStorage.getItem('webtoken');
      let currentUser = JSON.parse(localStorage.getItem('userData'));
      if(token && token != '') {
        messaging.deleteToken().then(()=>{}).catch(() => {})
        let obj = {
          fcmToken: token ? firebaseGlobal.firestore.FieldValue.arrayRemove(token) : [],
          'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
        }
        updateQuery.updateRootCollectionDataByDocIdV2(dbCollections.USERS,currentUser.id,obj,() => {
            let obj1 = {
              fcmToken: token ? firebaseGlobal.firestore.FieldValue.arrayRemove(token) : []
            }
            updateQuery.updateRootCollectionDataByDocIdV2(dbCollections.SUBSCRIBEDUSERS,'student',obj1,(res)=>{
              localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
              localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
              localStorage.removeItem('userData')
              localStorage.removeItem('userAccess');
              localStorage.removeItem('webtoken');
              this.$ability.update(initialAbility)
              localStorage.removeItem('currentUser')     
               firebase
                .auth()
                .signOut()
                .then(() => {
                  this.$router.push({
                    name: 'auth-login' 
                  });
                });
            })
        })
      } else {
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
          localStorage.removeItem('userData')
          localStorage.removeItem('userAccess');
          this.$ability.update(initialAbility)
          localStorage.removeItem('currentUser')     
          firebase
            .auth()
            .signOut()
            .then(() => {
              this.$router.push({
                name: 'auth-login' 
              });
            });
      }
    
    },
  },
  beforeDestroy() {
    this.$root.$off('logoutUser')
  }
}
</script>
